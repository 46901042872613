<template>
  <v-sheet
    :height="$vuetify.breakpoint.smAndUp ? `${upTo}` : `${upMax}`"
    color="primary"
    tile
  >
    <v-img
      :key="image"
      :src="image"
      gradient="to top, rgba(5, 82, 181, 0.9), rgba(0, 0, 0, .8)"
      height="100%"
    >
      <v-container
        fill-height
        white--text
      >
        <v-slide-x-transition appear>
          <v-col
            align="center"
            justify="center"
          >
            <h1 v-if="home" :class="$vuetify.breakpoint.smAndUp ? 'display-3' : 'display-1'" class="mb-2" v-text="title" />
            <h2 v-else :class="$vuetify.breakpoint.smAndUp ? 'display-2' : 'display-1'" class="mb-2 my-5" v-text="title" />

            <div
              class="title font-weight-bold  secondary--text"
              v-text="subtitle"
            />
            <div
              class="title font-weight-light"
              v-text="content"
            />
            <div
              class="title font-weight-bold orange--text"
              v-text="promo"
            />
          </v-col>
        </v-slide-x-transition>
      </v-container>
    </v-img>
  </v-sheet>
</template>

<script>
  export default {
    mounted(){
      //console.log(this.$route.name)
      if (this.$route.name == 'home') { this.home = true}
    },
    data: () => ({
      home: false,
      titles: {
        index: {
          title: 'AJ Producciones y Eventos, S.C.',
          subtitle: 'El éxito de su evento es nuestro principal objetivo!',
          content: 'Consultoría, producción y organización profesional de eventos científicos y corporativos',
          promo: 'También le organizamos su evento en formato virtual',
          upTo: '80vh',
          upMax: '100vh'
        },
        about: {
          title: 'NOSOTROS',
          subtitle: 'AJProducciones y Eventos',
          content: '',
          promo: '',
          upTo: '40vh',
          upMax: '40vh'
        },
        event: {
          title: 'EVENTOS',
          subtitle: '',
          content: '',
          promo: '',
          upTo: '40vh',
          upMax: '40vh'
        },
        event_id: {
          title: 'EVENTOS',
          subtitle: '',
          content: '',
          promo: '',
          upTo: '40vh',
          upMax: '40vh'
        },
        event_register_id: {
          title: 'EVENTOS',
          subtitle: 'MÓDULO DE INSCRIPCIONES',
          content: '',
          promo: '',
          upTo: '40vh',
          upMax: '40vh'
        },
        contact: {
          title: 'Contáctanos',
          subtitle: '',
          content: '',
          promo: '',
          upTo: '40vh',
          upMax: '40vh'
        },
        certificate: {
          title: 'Certificados',
          subtitle: '',
          content: '',
          promo: '',
          upTo: '40vh',
          upMax: '40vh'
        },
        information: {
          title: 'EVENTOS',
          subtitle: '',
          content: '',
          promo: '',
          upTo: '40vh',
          upMax: '40vh'
        },
        register: {
          title: 'EVENTOS',
          subtitle: '',
          content: '',
          promo: '',
          upTo: '40vh',
          upMax: '40vh'
        }
      }
    }),

    computed: {
      namespace () {
        if (this.$route.name == 'event-id') return 'event_id'
        if (this.$route.name == 'event-register-event_id') return 'event_register_id'

        if (!this.$route || !this.$route.name) return ''

        return this.$route.name.toLowerCase()
      },
      image () {
        return '/static/jumbotrom/' + this.namespace + '-hero.jpg'
      },
      subtitle () {
        return this.titles[this.namespace].subtitle
      },
      title () {
        return this.titles[this.namespace].title
      },
      content () {
        return this.titles[this.namespace].content
      },
      promo () {
        return this.titles[this.namespace].promo
      },
      upTo (){
        return this.titles[this.namespace].upTo
      },
      upMax (){
        return this.titles[this.namespace].upMax ? this.titles[this.namespace].upMax : '40vh'
      }
    }
  }
</script>
